import sharedb from 'sharedb/lib/client'
import type { ShallowRef } from 'vue'
import { onUnmounted, ref, shallowRef, watch } from 'vue'
import { fabric } from 'fabric'
import ReconnectingWebSocket from 'reconnecting-websocket'
import type { ICanvasPanEvent, ICanvasZoomEvent } from '../services/whiteboard'
import type Whiteboard from '../services/whiteboard'
import type { IPresenceRecord } from './presence'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { whiteboardConstants } from '@/models/constants'
import { getArticleAssets } from '@/api/t1/article'

interface IUserData {
  zoomFactor: number
  zoomPoint?: IPoint
  panViewport: number[]
}

export default function useCollaboration(whiteboardId: number, wb: ShallowRef<Whiteboard | undefined>) {
  const boardId = `${appConfig.T1Env}${whiteboardId}`
  const dbConn = shallowRef<sharedb.Connection>()
  const dbDoc = shallowRef<sharedb.Doc>()
  const userDoc = shallowRef<sharedb.Doc>()
  const userStore = useUserStore()
  const collaborationReady = ref(false)
  const connected = ref(false)
  let userData: IUserData = { zoomFactor: 1, panViewport: [] }
  let presence: sharedb.Presence
  let localPresence: sharedb.LocalPresence<IPresenceRecord>
  let ws: ReconnectingWebSocket

  watch(wb, () => {
    if (dbConn.value) {
      dbConn.value.close()
      dbConn.value = undefined
    }

    if (dbDoc.value) {
      dbDoc.value.destroy(() => register())
      dbDoc.value = undefined
    }
    else {
      register()
    }
  }, { immediate: true })

  onUnmounted(() => {
    if (dbDoc.value) { dbDoc.value.destroy() }
    if (userDoc.value) { userDoc.value.destroy() }
    if (localPresence) { localPresence.destroy() }
    if (presence) { presence.destroy() }
    if (ws) { ws.close(4999, 'Unmounted') }
  })

  function register() {
    if (!wb.value) { return }
    collaborationReady.value = false

    // Create WebSocket
    console.log('Registering', appConfig)
    ws = new ReconnectingWebSocket(`${appConfig.ShareDbUrl}/server?t=${encodeURIComponent(localStorage.getItem('tk') || '')}`)
    ws.addEventListener('open', () => {
      connected.value = true
      console.log('Connected to WS')
    })
    ws.addEventListener('close', (e) => {
      connected.value = false
      console.log('Disconnected from WS', e)
    })

    // Create connection
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    dbConn.value = new sharedb.Connection(ws)
    dbDoc.value = dbConn.value.get('boards', boardId)
    // Subscribe to changes in the document
    dbDoc.value.subscribe((err) => {
      if (err) {
        console.error(err)
        return
      }

      // if document has been fetch but type is not set it means document has not been created yet https://share.github.io/sharedb/api/doc#type--type
      if (!dbDoc.value?.type) {
        // Document does not exist, create it
        dbDoc.value?.create({ version: 0 })
        collaborationReady.value = true
      }
      else {
        // Load initial objects
        const objectOrder = new Set<string>()
        if (dbDoc.value.data?.objectOrder && dbDoc.value.data.objectOrder.length) {
          dbDoc.value.data.objectOrder.forEach(id => objectOrder.add(id))
        }

        for (const key in dbDoc.value.data) {
          if (key !== 'version' && key !== 'objectOrder') {
            objectOrder.add(key)
          }
        }

        const objs: any[] = []
        const groups: any[] = []
        for (const key of objectOrder) {
          if (dbDoc.value.data[key]) {
            if (dbDoc.value.data[key].type !== 'group') {
              objs.push(dbDoc.value.data[key])
            }
            else {
              groups.push(dbDoc.value.data[key])
            }
          }
        }

        wb.value?.addObjectsFromJson(objs).then(() => {
          collaborationReady.value = true
          wb.value?.performMoveToObject()
        })

        if (groups.length) {
          groups.forEach((group) => {
            if (group.objects && group.objects.length > 0) {
              // Create group from objects
              wb.value?.addGroupFromJson(group).then(() => {
                collaborationReady.value = true
                wb.value?.performMoveToObject()
              })
            }
          })
        }
      }
    })

    userDoc.value = dbConn.value.get('usersData', userStore.userProfile.UserName)
    userDoc.value.subscribe((err) => {
      if (err) {
        console.error(err)
        return
      }
      if (!userDoc.value?.type) {
        // Document does not exist, create it
        userDoc.value?.create({ version: 0 })
      }
      else if (utils.isDefined(userDoc.value?.data) && utils.isDefined(userDoc.value?.data[boardId])) {
        userData = userDoc.value.data[boardId]
        if (userData.panViewport.length) {
          wb.value?.canvas.setViewportTransform(userData.panViewport)
        }
        wb.value?.zoom(userData.zoomFactor, userData.zoomPoint)
      }
    })

    // Handle operations from shareDB
    dbDoc.value.on('op', async (op, source) => {
      console.log('new op', op, source)
      if (source || !op || !op[0] || !op[0].p || !op[0].p[0]) { return }

      const objs: any = wb.value?.canvas.getObjects()

      if (op[0].p[0] === 'objectOrder') {
        const newOrder = op[0].oi
        if (newOrder && newOrder.length) {
          newOrder.forEach((id: string, index: number) => {
            const obj = objs.find(obj => obj.id === id)
            if (obj) {
              wb.value!.canvas.moveTo(obj, index)
            }
          })
          wb.value!.canvas.requestRenderAll()
        }
      }
      else {
        const obj = objs?.find(o => o.id === op[0].p[0])

        if (op[0].hasOwnProperty('oi')) {
          // Operation is to insert/update object
          if (obj) {
            // Object exists, update
            console.warn('updating', op[0].oi)
            if (obj.type === whiteboardConstants.objectTypes.articleImage && op[0].oi.assetKey && op[0].oi.assetKey.toLowerCase() === 'temp') {
              const catalogDetails = userStore.linkedCatalogDetails[obj.CatalogCode] || userStore.activeCatalog
              const assets = await getArticleAssets(catalogDetails.DuneContext, catalogDetails.ContextKey, obj.articleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList)
              obj.assets = assets
              obj.imgLoaded = false
              obj.imgLoading = false
            }
            obj.set(op[0].oi)
            // const selObjs = wb.value!.canvas.getActiveObjects()
            // if (selObjs && selObjs.length > 0) {
            //   wb.value!.canvas.discardActiveObject()
            //   const sel = new fabric.ActiveSelection(selObjs, { canvas: wb.value!.canvas })
            //   sel.setObjectsCoords()
            //   sel.setCoords()
            //   wb.value!.canvas.setActiveObject(sel)
            // }
            obj.dirty = true
            wb.value!.canvas.requestRenderAll()
            wb.value!.emitObjectModifiedServer(obj)
          }
          else {
            // New object, create
            const newObject = op[0].oi
            console.warn('adding', newObject)

            let articleExistInCurrentCatalog = true
            if (newObject.type && (newObject.type === whiteboardConstants.objectTypes.articleImage || newObject.type === whiteboardConstants.objectTypes.articleDetails)) {
              articleExistInCurrentCatalog = await appConfig.DB!.doesArticleIdExistInCatalog(newObject.articleId, userStore.activeCatalog!.CatalogCode)
            }
            else if (newObject.type && (newObject.type === whiteboardConstants.objectTypes.modelImage || newObject.type === whiteboardConstants.objectTypes.modelDetails)) {
              articleExistInCurrentCatalog = await appConfig.DB!.doesModelNumberExistInCatalog(newObject.modelNumber, userStore.activeCatalog!.CatalogCode)
            }

            // if added article does not exist in current catalog reload data to get added articles
            if (!articleExistInCurrentCatalog) {
              await userStore.doLoadData(['Articles'])
            }

            newObject.excludeFromExport = true
            wb.value?.addObjectsFromJson([newObject])
              .then(() => wb.value?.canvas.requestRenderAll())
              .finally(() => newObject.excludeFromExport = undefined)
          }
        }
        else if (op[0].hasOwnProperty('od')) {
          // Operation is to remove object
          if (obj) {
            console.warn('removing')
            wb.value?.canvas.remove(obj)
            wb.value?.canvas.requestRenderAll()
          }
        }
      }
    })

    // Handle events on the canvas
    // Object added
    wb.value.canvas.on('object:added', (e) => {
      const obj = e.target?.toObject()
      if (obj?.id && !e.target?.excludeFromExport && collaborationReady.value) {
        console.log('object added', obj)
        dbDoc.value?.submitOp([{ p: [obj.id], oi: obj }])
      }
    })

    // Object modified
    wb.value.canvas.on('object:modified', (e) => {
      if (e.target) {
        console.log('object modified', collaborationReady.value, e.target)

        if (e.target.isType('activeSelection')) {
          // If it's a selection, then iterate thru all objects within
          const objs = wb.value!.canvas.getActiveObjects()
          wb.value!.canvas.discardActiveObject()
          objs.forEach((o: any) => {
            const obj = o.toObject()
            if (obj?.id && collaborationReady.value) {
              dbDoc.value?.submitOp([{ p: [obj.id], oi: obj }])
            }
          })
          const sel = new fabric.ActiveSelection(objs, { canvas: wb.value!.canvas })
          sel.setObjectsCoords()
          sel.setCoords()
          wb.value!.canvas.setActiveObject(sel)
          wb.value!.canvas.requestRenderAll()
        }
        else {
          const activeObjects = wb.value!.canvas.getActiveObjects()
          if (activeObjects.length > 1) {
            wb.value!.canvas.discardActiveObject()
            const targetObj = e.target.toObject()
            activeObjects.forEach((o: any) => {
              const obj = o.toObject()
              if (obj?.id && targetObj.id === obj?.id && collaborationReady.value) {
                dbDoc.value?.submitOp([{ p: [obj.id], oi: obj }])
              }
            })
            const sel = new fabric.ActiveSelection(activeObjects, { canvas: wb.value!.canvas })
            sel.setObjectsCoords()
            sel.setCoords()
            wb.value!.canvas.setActiveObject(sel)
            wb.value!.canvas.requestRenderAll()
          }
          else {
            const obj = e.target.toObject()
            if (obj?.id && collaborationReady.value) {
              dbDoc.value?.submitOp([{ p: [obj.id], oi: obj }])
            }
          }
        }
      }
    })

    // Object deleted
    wb.value.canvas.on('object:removed', (e) => {
      if (e.target) {
        const obj = e.target.toObject()
        console.log('object:removed', obj)
        if (obj.id && collaborationReady.value) {
          dbDoc.value?.submitOp([{ p: [obj.id], od: obj }])
        }
      }
    })

    // Objects Order
    wb.value.on('object-order', () => {
      if (dbDoc.value && dbDoc.value.data && wb.value) {
        const objectOrder = wb.value.canvas.getObjects().map((obj: any) => obj.id)
        dbDoc.value.submitOp([{ p: ['objectOrder'], oi: objectOrder }])
      }
    })

    wb.value.on('zoom', (e: ICanvasZoomEvent) => {
      userData.zoomFactor = e.factor
      userData.zoomPoint = e.point
      userDoc.value?.submitOp([{ p: [boardId], oi: userData }])
    })

    wb.value.on('pan', (e: ICanvasPanEvent) => {
      userData.panViewport = e.viewport
      userDoc.value?.submitOp([{ p: [boardId], oi: userData }])
    })
  }

  return { dbConn, dbDoc, userDoc, collaborationReady, connected }
}
